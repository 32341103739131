define([
  'jquery',
  'application',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
], (
  $, App, _, Backbone, Locale, AppLocalStorage, DeviceConfig,
) => {
  const Model = Backbone.DeepModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/scaleType'),

    configPath: DeviceConfig.PATH_ScaleType,

    DEFAULT: 'erp',

    TYPE_CAS_ER: 'crs_er',
    TYPE_DEV: 'dev',
    TYPE_ECR_TYPE_6: 'ecr_type_6',
    TYPE_SAMSUNG_PORTUGAL: 'samsung_portugal',
    TYPE_BASIC: 'basic',

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    setByModel(model) {
      this.clear({ silent: true });
      this.set(model.toJSON());
      this.save();
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set({ id: null });
    },

    getIdOrDefault() {
      return this.get('id') || this.TYPE_BASIC;
    },

    hasDevScale() {
      const setting = App.settings['shop-cash-register'];
      return setting && setting.dev_weight_scale;
    },

    getFullCollection() {
      const col = new Backbone.Collection();
      if (this.hasDevScale()) {
        col.add({
          id: this.TYPE_DEV,
          name: 'Dev weight scale',
        });
      }
      col.add({
        id: this.TYPE_CAS_ER,
        name: Locale.translate('ecr_type4_cas'),
      });
      col.add({
        id: this.TYPE_ECR_TYPE_6,
        name: Locale.translate('ecr_type6_mettler_toledo'),
      });
      col.add({
        id: this.TYPE_SAMSUNG_PORTUGAL,
        name: 'Samsung portugal (9600-8bit-even-1)',
      });
      col.add({
        id: this.TYPE_BASIC,
        name: Locale.translate('storekeeper_basic'),
      });
      return col;
    },

  });

  return new Model();
});
