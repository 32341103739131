define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/currency',
  'modules/shop.common/components/taxCalculator',
], (
  $, _, Backbone, Currency, TaxCalculator,
) => Backbone.DeepModel.extend({

  idAttribute: 'id',

  modelEvents: {
    change: 'save',
  },

  is_order_item: true,

  addOne() {
    this.addQuantity(1);
  },

  addQuantity(qty) {
    const amount = parseInt(qty);
    const quantity = parseInt(this.get('quantity'));
    this.setQuantity(quantity + amount);
  },

  setQuantity(qty) {
    const quantity = parseInt(qty);
    const updates = {
      // not sure why 'quantity' is a string, but I'm afraid to change it ~ Szymon
      quantity: (quantity).toString(),
      to_be_shipped_quantity: quantity,
      unfulfilled_quantity: quantity,
    };
    this.set(updates);
    this.calculateTotalPrices();
    this.updateSubItemQuantity();
    this.save();
  },

  updateSubItemQuantity(silent) {
    silent = silent || false; // to prevent an infinite loop when calling getCalculatedSubItems
    if (this.has('sub_items')) {
      const modelQuantity = this.get('quantity');
      const subItems = this.get('sub_items');
      subItems.forEach((item) => {
        item.quantity = modelQuantity;
      });
      this.unset('sub_items', { silent: true });

      const options = { silent };
      this.set({
        sub_items: subItems,
      }, options);
    }
  },

  setReturnQuantity(quantity, save) {
    save = _.isBoolean(save) ? save : true;

    this.set('return_quantity', quantity.toString());
    save ? this.save() : '';
  },

  setPpu(ppu) {
    const tax = parseFloat(this.get('tax'));
    let ppuWt = TaxCalculator.nettoToGross(ppu, tax);
    ppuWt = Currency.toCurrency(ppuWt);
    this.set({
      ppu,
      ppu_wt: ppuWt,
    });
    this.calculateTotalPrices();
    this.save();
  },

  setPpuWt(ppuWt, save = true) {
    const tax = parseFloat(this.get('tax'));
    let ppu = TaxCalculator.grossToNetto(ppuWt, tax);
    ppu = Currency.toCurrency(ppu);
    this.set({
      ppu,
      ppu_wt: ppuWt,
    });
    this.set({
      discount_ppu_wt: this.getDiscountPpuWt(),
    });
    this.calculateTotalPrices();
    if (save) {
      // needed when it's not stored in localstorage
      this.save();
    }
  },

  clearLengthPriceIfSet() {
    if (this.has('length') && parseFloat(this.get('length')) > 0) {
      this.set({
        length: '0.00',
        description: this.get('order_description'),
      });
    }
  },

  setLengthPpuWt(lengthPpuWt) {
    this.set({
      length_ppu_wt: lengthPpuWt,
    });
    this.save();
  },

  setLength(length) {
    this.set({
      length,
    });
    this.save();
  },

  setOrderDescription(orderDescription) {
    this.set({
      order_description: orderDescription,
    });
    this.save();
  },

  setSerialNos(serials, allowNegativeQty = false) {
    this.unset('serial_nos');
    const serial_nos = this.parseSerialNos(serials);
    this.set({
      serial_nos,
    });
    this.save();

    const isNegative = this.get('quantity') < 0;
    if (!isNegative) {
      if (serial_nos.length > this.get('quantity')) {
        this.setQuantity(serial_nos.length);
      }
    } else if (allowNegativeQty) {
      if (-serial_nos.length < this.get('quantity')) {
        this.setQuantity(-serial_nos.length);
      }
    }
  },

  hasFilledInSerialNumbers() {
    return (this.get('serial_nos') || []).length > 0;
  },

  parseSerialNos(value) {
    if (_.isArray(value)) {
      value = value.join(' ');
    }
    let serial_nos = [];
    if (value && _.isString(value)) {
      const hasNumberOrLetter = /\w/;
      value.match(/\S+/gmu).forEach((element) => {
        const id = element.toLocaleUpperCase();
        if (hasNumberOrLetter.test(id)) {
          serial_nos.push(id);
        }
      });
      serial_nos = _.uniq(serial_nos);
    }
    return serial_nos;
  },

  setDescription(description) {
    this.set({
      description,
    });
    this.save();
  },

  calculateTotalPrices() {
    // only calculating from with vat for now
    let ppuWt = Currency.toCurrency(this.get('ppu_wt'));
    if (this.has('discount_ppu_wt')) {
      const discountWt = Currency.toCurrency(this.get('discount_ppu_wt'));
      ppuWt = Currency.Math.subtract(ppuWt, discountWt);
    }
    const quantity = this.get('quantity');
    const priceWt = Currency.Math.mul(ppuWt, this.toCorrectQuantity(quantity));
    const tax = parseFloat(this.get('tax'));
    const price = Currency.toCurrency(TaxCalculator.grossToNetto(priceWt, tax));
    this.set({
      price,
      price_wt: priceWt,
    });
  },

  setDiscountPpuWt(newDiscount) {
    this.set({
      discount_ppu_wt: newDiscount,
    });
    this.set({
      discount_percentage: this.getDiscountPercentage(),
    });
  },
  setDiscountPercentage(newDiscount) {
    this.set({
      discount_percentage: newDiscount,
    });
    this.set({
      discount_ppu_wt: this.getDiscountPpuWt(),
    });
  },
  getDiscountPpuWt() {
    if (this.get('discount_percentage') > 0) {
      const ppuWt = this.get('ppu_wt');
      return Currency.Math.subtract(
        ppuWt,
        Currency.Math.percent(ppuWt,
          Currency.Math.subtract('100.00', this.get('discount_percentage'))),
      );
    }
    return '0.00';
  },
  getDiscountPercentage() {
    if (this.get('discount_ppu_wt') != 0) {
      // value (count the procentage)
      const unitPrice = this.get('ppu_wt');
      const discountPpu = this.get('discount_ppu_wt');
      const discountedUnitPrice = Currency.Math.subtract(unitPrice, discountPpu);
      const decrease = Currency.Math.subtract(unitPrice, discountedUnitPrice);

      // To make sure stuff behind the comma doesnt get lost.
      const decrease100 = Currency.Math.mul(decrease, '100.00');
      return Currency.Math.div(decrease100, unitPrice);
    }
    return '0.00';
  },

  toCorrectQuantity(quantity) {
    const intVal = parseInt(quantity);
    if (_.isNaN(intVal)) {
      console.error(`Quantity is not a number: ${quantity}`);
    }
    const floatVal = parseFloat(intVal);
    return floatVal.toFixed(2);
  },

  formatPrice(price, currency_iso) {
    currency_iso = currency_iso || 'EUR';
    if ((typeof price) === 'number') {
      price = Currency.toCurrency(price);
    }
    return Currency.Math.format(currency_iso, price);
  },

  getCalculatedSubItems() {
    if (this.has('sub_items')) {
      this.updateSubItemQuantity(true);

      const subItems = this.get('sub_items');
      subItems.forEach((item) => {
        // Set price_wt
        const itemPpuWt = Currency.toCurrency(item.ppu_wt);
        const itemQuantity = this.toCorrectQuantity(item.quantity);
        item.price_wt = Currency.Math.mul(itemPpuWt, itemQuantity);
      });
      return subItems;
    }
    return [];
  },

  setSubItemPpuWt(frontendId, ppu_wt, tax) {
    if (this.has('sub_items')) {
      const subItems = this.get('sub_items');
      subItems.forEach((item) => {
        if (frontendId === item.extra.frontend_id) {
          item.ppu_wt = Currency.toCurrency(ppu_wt);
          item.tax = tax;
        }
      });
    }
  },

  getPriceWtWithSubItems() {
    this.calculateTotalPrices();
    let priceWt = Currency.toCurrency(this.get('price_wt'));

    const subItems = this.getCalculatedSubItems() || [];
    subItems.forEach((item) => {
      priceWt = Currency.Math.add(priceWt, item.price_wt);
    });

    return priceWt;
  },

  resetShipped() {
    this.set({
      to_be_shipped_quantity: parseInt(this.get('quantity'), 10),
    });
  },

  updateQuantity(updateQuantity) {
    // Extract data
    const newQuantity = parseInt(updateQuantity, 10);

    // Reset the current values
    this.set({
      quantity: newQuantity.toString(),
      to_be_shipped_quantity: newQuantity,
      unfulfilled_quantity: newQuantity,
    });
  },

}));
