define([
  'jquery',
  'underscore',
  'backbone',
  './printable',
], (
  $, _, Backbone, AbstractPrintable,
) => AbstractPrintable.extend({

  build(data) {
    this.ccvPinReceipt(data.textLines);

    this.builder.cut();

    return this.serialize();
  },
}));
