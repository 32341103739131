define([
  'underscore',
  'jquery',
  'modules/upx/components/upx',
  'upx.modules/ShopModule/models/LoyaltyProgram',
], (
  _, $, Upx, LoyaltyProgramModel,
) => {
  const Model = LoyaltyProgramModel.extend({

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ShopModule', 'listLoyaltyPrograms',
          {
            start: 0,
            limit: 1,
          })).then((response) => {
          self.unload();
          if (response.data.length) {
            self.set(response.data[0]);
          }
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load LoyaltyProgramModel!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    fetchLoyaltyCustomer(relation_data_id) {
      const def = $.Deferred();
      Upx.call(
        'ShopModule', 'listLoyaltyCustomers',
        {
          start: 0,
          limit: 1,
          filters: [
            {
              name: 'loyalty_program_id__=',
              val: this.get('id'),
            },
            {
              name: 'relation_data_id__=',
              val: relation_data_id,
            },
          ],
        },
      ).then(
        (resp) => {
          const { data } = resp;
          def.resolve(_.first(data));
        },
        def.reject,
      );
      return def.promise();
    },

    isActive() {
      return !!this.get('active');
    },

    get(name) {
      if (!this.loaded) {
        throw new Error('LoyaltyProgramModel is queried before loading');
      }
      return LoyaltyProgramModel.prototype.get.call(this, name);
    },

    unload() {
      this.clear();
    },
  });

  return new Model();
});
