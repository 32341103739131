define([
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
], (
  Backbone, Locale, AppLocalStorage, DeviceConfig,
) => {
  const Model = Backbone.DeepModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/keyboardLayout'),

    configPath: DeviceConfig.PATH_KeyboardLayout,

    TYPE_QWERTY: 'qwerty',
    TYPE_QWERTZ: 'qwertz',
    TYPE_NONE: 'none',

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    setByModel(model) {
      this.clear({ silent: true });
      this.set(model.toJSON());
      this.save();
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set({ id: null });
    },

    getDefault() {
      return Locale.getLocale() === 'de' ? this.TYPE_QWERTZ : this.TYPE_QWERTY;
    },

    getIdOrDefault() {
      return this.get('id') || this.getDefault();
    },

    getFullCollection() {
      const col = new Backbone.Collection();
      col.add({
        id: this.TYPE_QWERTY,
        name: 'QWERTY',
      });
      col.add({
        id: this.TYPE_QWERTZ,
        name: 'QWERTZ',
      });
      col.add({
        id: this.TYPE_NONE,
        name: Locale.translate('none'),
      });
      return col;
    },
  });

  return new Model();
});
