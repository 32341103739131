define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.common/components/crypto',
  'modules/shop.cash-register-retail/components/orderMemory',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/orderItem',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  'modules/profile/models/profile',
], (
  $, _, Backbone,
  Locale, Crypto, OrderMemory,
  CurrentOrderItemCollection, OrderItemCollection,
  ConfirmPopup,
  Profile,
) => Backbone.DeepModel.extend({

  idAttribute: 'id',

  defaults() {
    return {
      name: Locale.translate('tab'),
      date_created: new Date().getTime(),
      date_updated: new Date().getTime(),
      author_name: Profile.getFullProfileName(),
      order_items: [], // This is not a collection because it goes bad with serialisation
    };
  },

  getCurrencyIso3() {
    const modelOrderItems = new OrderItemCollection(this.get('order_items'));
    let iso3 = 'EUR';
    if (modelOrderItems.length > 0) {
      iso3 = modelOrderItems.first().get('currency_iso3');
    }
    return iso3;
  },

  getOrderTotals() {
    const modelOrderItems = new OrderItemCollection(this.get('order_items'));
    return modelOrderItems.getTotalPriceWt();
  },

  pullFromOrder() {
    this.mergeWithOrderItemCollection(CurrentOrderItemCollection);
    CurrentOrderItemCollection.clear();
  },

  pushToOrder() {
    const def = new $.Deferred();
    const confirmDef = new $.Deferred();
    if (CurrentOrderItemCollection.length > 0) {
      const view = new ConfirmPopup();
      view.open(
        Locale.translate('this_action_will_clear_items_in_order_dot'),
        Locale.translate('there_are_items_in_your_current_order_these_will_be_removed_when_proceeding_dot'),
        confirmDef,
      );
    } else {
      confirmDef.resolve();
    }

    confirmDef.then(() => {
      OrderMemory.replaceOrderItemsInCheckout(this.get('order_items')).then(
        def.resolve,
        def.reject,
      );
    }, def.reject);

    return def;
  },

  getRegion() {
    const body = $('body');
    const el = $('<div>');
    body.append(el);
    return new Backbone.Marionette.Region({
      el,
    });
  },

  clearRegion(region) {
    region.reset();
    region.options.el.remove();
  },

  getOrderItemCollection() {
    return new OrderItemCollection(this.get('order_items') || []);
  },

  mergeWithTab(otherTab) {
    const otherOrderItemCollection = new OrderItemCollection(otherTab.get('order_items'));
    this.mergeWithOrderItemCollection(otherOrderItemCollection);
  },

  updateName(newName) {
    this.set('name', newName);
    this.set('date_updated', new Date().getTime());
    this.save();
  },

  mergeWithOrderItemCollection(otherOrderItemCollection) {
    const modelOrderItems = new OrderItemCollection(this.get('order_items'));
    const time = new Date().getTime();

    // Loop overall current items in the collection to add them to the order_items
    otherOrderItemCollection.each((model) => {
      const data = _.extend({}, model.toJSON(), {
        time,
        author_name: Profile.getFullProfileName(),
      });
      if (!Crypto.isUuid(data.id)) data.id = Crypto.uuid();
      modelOrderItems.add(data);
    });

    this.unset('order_items', { silent: true });
    this.set('order_items', modelOrderItems.toJSON());
    this.set('date_updated', new Date().getTime());
    this.save();
  },

  clearTab() {
    this.unset('order_items');
    this.set('order_items', []);
    this.save();
  },

}));
