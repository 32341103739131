define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/common/components/currency',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment, Currency,
) => AbstractPrintable.extend({

  build(data) {
    if (data.options && data.options.number) {
      this.builder
        .text(data.options.number.toString(), {
          size: 4, bold: true, alignHT: 'center', newLine: true,
        })
        .linefeed();
    }

    this.companyLogo();

    // Header text
    if (data.header_text) {
      this.headerText(data.header_text);
    }

    this.builder.text(Locale.translate('repair_{number}', { number: data.number }), { newLine: true, size: 2 }).linefeed();

    // Customer name
    if (data.customer_name) {
      this.builder
        .text(data.customer_name, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    this.builder
      .text(Moment().format('LLL'), { newLine: true, breakOnWord: true })
      .linefeed();

    this.repairDetails(data);
    this.repairPrice(data);

    this.builder
      .text(`${Locale.translate('you_were_helped_by')}: ${data.cashier_name}`, { newLine: true, breakOnWord: true })
      .linefeed();

    this.builder
      .text(data.repair_receipt_footnote_text, { newLine: true, breakOnWord: true })
      .linefeed();

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  repairDetails(data) {
    this.builder
      .text(`${Locale.translate('repair_details')}:`, { bold: true, newLine: true })
      .text(`${Locale.translate('product_name')}: ${data.repaired_item_name}`, { newLine: true, breakOnWord: true });

    if (data.repaired_item_serial_no) {
      this.builder
        .text(`${Locale.translate('serial_number')}: ${data.repaired_item_serial_no}`, { newLine: true, breakOnWord: true });
    }

    this.builder
      .text(`${Locale.translate('issue')}: ${data.description}`, { newLine: true, breakOnWord: true })
      .linefeed();
  },

  repairPrice(data) {
    let maxAgreedCost = null;
    if (data.max_agreed_cost && data.max_agreed_cost > 0) {
      maxAgreedCost = Currency.toCurrency(data.max_agreed_cost.toFixed(2));
    } else {
      maxAgreedCost = '-';
    }

    this.builder
      .text(`${Locale.translate('maximum_agreed_costs_in_{currency_iso3}', { currency_iso3: data.currency_iso3 })}: ${maxAgreedCost}`, { newLine: true, breakOnWord: true })
      .linefeed();
  },

}));
