define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
], (
  $, _, Backbone, AbstractPrintable,
  Locale,
) => AbstractPrintable.extend({

  build(data) {
    this.builder
      .text(data.group_name, {
        size: 2, bold: true, alignHT: 'center', newLine: true,
      })
      .linefeed();

    const isProduction = data.type !== 'production';
    if (isProduction) {
      this.builder.line({ character: '═' });
    }

    this.builder
      .text(data.type_name.toUpperCase(), {
        size: 3, bold: true, alignHT: 'center', newLine: true,
      });

    if (isProduction) {
      this.builder.line({ character: '═' });
    }

    this.extraInfoTable(data);
    this.productsTable(data);
    this.waiterTable(data);

    if (data.direct_number) {
      this.builder
        .linefeed()
        .text(data.direct_number, {
          size: 5, bold: true, alignHT: 'center', newLine: true,
        });
    }

    this.builder
      .cut();

    return this.serialize();
  },

  productsTable(data) {
    const productRows = [];

    data.order_items.forEach((orderItem, i) => {
      const lineSpacing = 70;

      const hasDescription = !!orderItem.description;
      const hasSubitems = orderItem.subitems && orderItem.subitems.length > 0;
      const isLast = i === data.order_items.length - 1;

      productRows.push({
        data: {
          quantity: `${orderItem.quantity}x`,
          product: orderItem.name,
        },
        bold: true,
        size: 2,
        lineSpacing: !hasDescription && !hasSubitems && !isLast ? lineSpacing : undefined,
      });
      if (hasDescription) {
        productRows.push({
          data: {
            quantity: '',
            product: `  ${orderItem.description}`,
          },
          size: 2,
          lineSpacing: !hasSubitems && !isLast ? lineSpacing : undefined,
        });
      }

      if (hasSubitems) {
        orderItem.subitems.forEach((subItem, i) => {
          const isLastSubitem = i === orderItem.subitems.length - 1;

          productRows.push({
            data: {
              quantity: '',
              product: `+ ${subItem.name}`,
            },
            size: 2,
            lineSpacing: !isLast && isLastSubitem ? lineSpacing : undefined,
          });
        });
      }
    });

    this.builder
      .table({
        columns: [
          {
            key: 'quantity',
            width: 20,
            bold: true,
          },
          {
            key: 'product',
            width: 80,
          },
        ],
        rows: productRows,
      })
      .line();
  },

  extraInfoTable(data) {
    this.builder
      .linefeed()
      .table({
        columns: [
          {
            key: 'name',
            width: 20,
            bold: true,
          },
          {
            key: 'value',
            width: 80,
          },
        ],
        rows: [
          {
            data: {
              name: `${Locale.translate('date')}:`,
              value: data.created_date,
            },
          },
          data.table_name && {
            data: {
              name: `${Locale.translate('table')}:`,
              value: data.table_name,
            },
          },
        ],
      })
      .line();
  },

  waiterTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'name',
            width: 20,
            bold: true,
          },
          {
            key: 'value',
            width: 80,
          },
        ],
        rows: [
          {
            data: {
              name: `${Locale.translate('waiter')}:`,
              value: data.created_by_name,
            },
          },
        ],
      })
      .line();
  },

}));
