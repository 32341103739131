define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
  '@storekeeper/escpos',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment, EscPos,
) => AbstractPrintable.extend({

  build(data) {
    this.companyLogo();

    this.builder.text(Locale.translate('daily_returns'), {
      alignHT: 'center', size: 2, bold: true, newLine: true,
    });

    this.orderItemsTable(data);
    this.extraInfoTable(data);

    // Completed text
    this.builder
      .linefeed()
      .text(Locale.translate('completed'), { alignHT: 'center', bold: true });

    this.builder
      .cut();

    return this.serialize();
  },

  orderItemsTable(data) {
    const orderItemRows = [];

    data.order_items.forEach((orderItem) => {
      orderItemRows.push({
        data: {
          quantity: `${Math.abs(orderItem.quantity)}x `,
          product: orderItem.name,
          complete: new EscPos.BoxCellData(),
        },
        lineSpacingLineWrap: 0,
      });

      if (orderItem.serial_nos && orderItem.serial_nos.length) {
        orderItemRows.push(
          {
            data: {
              quantity: '',
              product: `${Locale.translate('serials')}: ${orderItem.serial_nos.join(', ')}`,
            },
            size: 0.5,
          },
        );
      }
    });

    this.builder
      .linefeed()
      .table({
        columns: [
          {
            key: 'quantity',
            width: 15,
            alignHTRows: 'right',
          },
          {
            key: 'product',
            width: 60,
            header: Locale.translate('product'),
          },
          {
            key: 'complete',
            width: 25,
            header: Locale.translate('complete'),
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: orderItemRows,
      });
  },

  extraInfoTable(data) {
    this.builder
      .line()
      .table({
        columns: [
          {
            key: 'name',
            width: 30,
          },
          {
            key: 'value',
            width: 70,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: Locale.translate('printed'),
              value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
            },
          },
        ],
      });
  },

}));
