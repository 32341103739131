define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
], (
  $, _, Backbone, AbstractPrintable,
  Locale,
) => AbstractPrintable.extend({

  build(data) {
    const isProduction = data.type !== 'production';
    if (isProduction) {
      this.builder.line({ character: '═' });
    }

    this.builder
      .text(data.type_name.toUpperCase(), {
        size: 3, bold: true, alignHT: 'center', newLine: true,
      });

    if (isProduction) {
      this.builder.line({ character: '═' });
    }

    this.builder
      .linefeed()
      .text(data.timeConfirmed, {
        size: 3, bold: true, alignHT: 'center', newLine: true,
      });

    this.extraInfoTable(data);
    this.productsTable(data);

    this.builder
      .cut();

    return this.serialize();
  },

  extraInfoTable(data) {
    this.builder
      .linefeed(2)
      .table({
        columns: [
          {
            key: 'name',
            width: 30,
            bold: true,
          },
          {
            key: 'value',
            width: 70,
          },
        ],
        rows: [
          {
            data: {
              name: `${Locale.translate('number')}:`,
              value: data.number,
            },
          },
          {
            data: {
              name: `${Locale.translate('type')}:`,
              value: Locale.translate(data.isPickup ? 'pickup' : 'delivery'),
            },
          },
          {
            data: {
              name: `${Locale.translate('customer')}:`,
              value: data.customerName,
            },
          },
          {
            data: {
              name: `${Locale.translate('remarks')}:`,
              value: data.notes ? data.notes : '-',
            },
          },
        ],
      })
      .line();
  },

  productsTable(data) {
    const productRows = [];

    data.items.forEach((orderItem, i) => {
      const longLineSpacing = 70;
      const defaultLineSpacing = 53;

      const hasDescription = !!orderItem.description;
      const hasSubitems = orderItem.sub_items && orderItem.sub_items.length > 0;
      const isLast = i === data.items.length - 1;

      productRows.push({
        data: {
          quantity: `${orderItem.quantity}x`,
          product: `[${orderItem.category}]`,
        },
        size: 2,
        lineSpacing: defaultLineSpacing,
      });
      productRows.push({
        data: {
          quantity: '',
          product: orderItem.name,
        },
        bold: true,
        size: 2,
        lineSpacing: !isLast && !hasSubitems && !hasDescription ? longLineSpacing : defaultLineSpacing,
      });

      if (hasDescription) {
        productRows.push({
          data: {
            quantity: '',
            product: `> ${orderItem.description}`,
          },
          size: 2,
          underline: true,
          lineSpacing: !isLast && !hasSubitems ? longLineSpacing : defaultLineSpacing,
        });
      }

      if (hasSubitems) {
        orderItem.sub_items.forEach((subItem, i) => {
          const isLastSubitem = i === orderItem.sub_items.length - 1;

          productRows.push({
            data: {
              quantity: '',
              product: `+ ${subItem.name}`,
            },
            size: 2,
            lineSpacing: !isLast && isLastSubitem ? longLineSpacing : defaultLineSpacing,
          });
        });
      }
    });

    this.builder
      .table({
        columns: [
          {
            key: 'quantity',
            width: 20,
            bold: true,
          },
          {
            key: 'product',
            width: 80,
          },
        ],
        rows: productRows,
      })
      .line();
  },

}));
