define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/upx/components/upx',
  'modules/upx/models/user',
  'modules/upx/acls/loggedIn',
], (
  $, _, Backbone, AppLocalStorage, Upx, User, LoggedInAcl,
) => {
  const Model = Backbone.Model.extend({

    defaults: {
      enabled: false,
    },

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/pinKey'),

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    registerDevice() {
      const deferred = new $.Deferred();
      const self = this;
      Upx.call('CommerceModule', 'getPinAuth', {
        fields: { configuration_id: '0' }, // set to 0 so backend can choose the correct one
      }).then(
        (response) => {
          self.set('enabled', true);
          self.set('auth', response);
          self.save();
          deferred.resolve();
        },
        (error) => {
          self.unregisterDevice();
          deferred.reject(error);
        },
      );
      return deferred.promise();
    },

    unregisterDevice() {
      this.set('enabled', false);
      this.unset('auth');
      this.save();
    },

    getAuth() {
      return this.get('auth');
    },

    callWithAuthIfEnabled(module, fn, params) {
      let defCall;
      if (this.isEnabled()) {
        const user = new User(this.getAuth());
        defCall = Upx.callWithUser(user, module, fn, params);
      } else if ((new LoggedInAcl()).authorized()) {
        defCall = Upx.call(module, fn, params);
      } else {
        defCall = new $.Deferred();
        defCall.reject('No auth to call with');
      }
      return defCall.promise();
    },

    isEnabled() {
      return !!this.get('enabled');
    },

  });

  return new Model();
});
