define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/ShopPos',

  'modules/common/components/locale',

  'upx.modules/ShopModule/collections/ShopPosClientGalleryImage',
], (
  $, _, Backbone, ShopPosModel,
  Locale,
  ShopPosClientGalleryImageCollection,
) => ShopPosModel.extend({

  loaded: false,

  getShopId() {
    return this.get('shop_id');
  },

  loadIfPossible(force) {
    let def = new $.Deferred();

    if (this.getShopId()) {
      def = this.load(force);
    } else {
      def.resolve();
    }

    return def;
  },

  load(force) {
    const def = new $.Deferred();

    if (force) this.unload();

    const posId = this.getShopId();
    if (!this.loaded) {
      if (posId) {
        this.set('shop_id', posId);
        this.fetch()
          .then(def.resolve, def.reject);
      } else {
        def.reject({
          error: Locale.translate('no_sales_location_set'),
        });
      }
    } else {
      def.resolve();
    }

    return def;
  },

  loadFromModel(shopPosModel) {
    this.unload();
    this.set(shopPosModel.toJSON());
    this.loaded = true;
  },

  unload() {
    this.clear();
    this.unset('payment_categories'); // for some reason it's Array(7) [empty × 7] after clear
    this.loaded = false;
  },

  fetchGalleryItems() {
    const def = new $.Deferred();

    const shopPosClientGalleryImageCollection = new ShopPosClientGalleryImageCollection();

    const shopId = this.getShopId();
    if (shopId) {
      const parameters = {
        shop_id: shopId,
        start: 0,
        limit: 0,
        sort: [{
          name: 'order',
          dir: 'asc',
        }],
      };

      shopPosClientGalleryImageCollection.refetch = function () {
        this.reset({ silent: true });
        return this.fetch({ params: parameters });
      },

      shopPosClientGalleryImageCollection.fetch({ params: parameters })
        .then(
          () => def.resolve(shopPosClientGalleryImageCollection),
          def.reject,
        );
    } else {
      def.resolve(shopPosClientGalleryImageCollection);
    }

    return def;
  },

}));
