define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/Order',

  'upx.modules/ShopModule/collections/OrderPayment',
  'upx.modules/ShopModule/collections/OrderShipment',
  'upx.modules/ShippingModule/collections/Shipment',

  'upx.modules/ShopModule/collections/OrderItem',
  'upx.modules/ShippingModule/collections/ShipmentItem',
  'modules/shop.cash-register-retail/components/feature.js',
  'modules/upx/components/upx',
], (
  $, _, Backbone, OrderModel,
  OrderPaymentCollection, OrderShipmentCollection, ShipmentCollection,
  OrderItemCollection, ShipmentItemCollection, Feature, Upx,
) => {
  const Struct = $.extend(true, {}, OrderModel.prototype.struct);
  Struct.crud.create = 'newOrderWithReturn'; // save function will return object instead of id

  return OrderModel.extend({

    struct: Struct,

    paymentItemsKey: 'payment_items',
    shipmentItemsKey: 'shipment_items',
    shipmentItemSerialsKey: 'shipped_serial_nos',
    orderItemsKey: 'order_items',

    loadOrderItems() {
      return this.fetch();
    },

    getOrderItemCollection() {
      this.checkIfItemsAreLoaded();
      return new OrderItemCollection(this.get(this.orderItemsKey) || []);
    },

    loadPaymentItems() {
      const def = new $.Deferred();
      const col = new OrderPaymentCollection();
      const params = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'date_created',
          dir: 'asc',
        }],
        filters: [{
          name: 'order_id__=',
          val: this.get('id'),
        }],
      };

      col.fetch({ params })
        .then((response) => {
          this.unset(this.paymentItemsKey);
          this.set(this.paymentItemsKey, response.data);
          def.resolve();
        }, def.reject);

      return def;
    },

    checkIfItemsAreLoaded() {
      if (!this.has(this.orderItemsKey)) {
        throw new Error(`${this.orderItemsKey} is not loaded`);
      }
    },
    loadShippedOrderItemSerials() {
      const def = new $.Deferred();
      this.checkIfItemsAreLoaded();
      if (Feature.isProductSerialFeatureEnabled()) {
        const items = this.get(this.orderItemsKey);
        items.forEach((m) => delete m[this.shipmentItemSerialsKey]);

        Upx.call('ShopModule', 'listShippedOrderItemSerials',
          {
            order_id: this.get('id'),
          }).then(
          ({ data }) => {
            if (data) {
              const shipped_serial_nos = {};
              data.forEach(
                ({ id, serial_nos }) => {
                  shipped_serial_nos[id] = serial_nos;
                },
              );
              items.forEach((m) => {
                if (m.id in shipped_serial_nos) {
                  m[this.shipmentItemSerialsKey] = shipped_serial_nos[m.id];
                }
              });
            }
            def.resolve();
          },
          def.reject,
        );
      } else {
        def.resolve(); // feature is not enabled -> nothing to load
      }

      return def;
    },

    getPaymentItemCollection() {
      if (!this.has(this.paymentItemsKey)) {
        throw new Error(`${this.paymentItemsKey} is not loaded`);
      }
      return new OrderPaymentCollection(this.get(this.paymentItemsKey) || []);
    },

    loadShipmentItems() {
      const def = new $.Deferred();
      const col = new OrderShipmentCollection();
      const shipmentParams = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'shipment/date_created',
          dir: 'asc',
        }],
        filters: [{
          name: 'order_id__=',
          val: this.get('id'),
        }, {
          name: 'shipment/deleted',
          val: 0,
        }],
      };

      const orderItems = this.getOrderItemCollection();
      col.fetch({ params: shipmentParams })
        .then(() => {
          const shipment_ids = col.pluck('shipment_id');
          if (shipment_ids.length) {
            const itemCollection = new ShipmentItemCollection();
            const itemParams = {
              start: 0,
              limit: 100,
              sort: [{
                name: 'rownb',
                dir: 'asc',
              }],
              filters: [{
                name: 'shipment_id__in_list',
                multi_val: shipment_ids,
              }],
            };
            itemCollection.fetchAll({ params: itemParams }).then(
              () => {
                itemCollection.each((itemModel) => {
                  const backref = itemModel.get('backref');
                  if (backref) {
                    const regex = /ShopModule::OrderItem\(id=(\d+)\)/;
                    const orderItemId = regex.exec(backref)[1];
                    const orderItem = orderItems.get(orderItemId);
                    if (orderItem) {
                      const imageUrl = orderItem.get('flat_product.main_image.url');
                      if (imageUrl) {
                        itemModel.set('main_image_url', imageUrl);
                      }
                    }
                  }

                  const shipmentModel = col.findWhere({
                    shipment_id: itemModel.get('shipment_id'),
                  });
                  const list = shipmentModel.get('shipment.shipment_items') || [];
                  list.push(itemModel.toJSON());
                  shipmentModel.set('shipment.shipment_items', list);
                });
                def.resolve();
              },
              def.reject,
            );
          } else {
            def.resolve();
          }
        }, def.reject);

      def.then(
        () => {
          const items = [];
          col.each((model) => {
            items.push(model.get('shipment'));
          });
          this.unset(this.shipmentItemsKey);
          this.set(this.shipmentItemsKey, items);
        },
      );

      return def;
    },

    getShipmentItemCollection() {
      if (!this.has(this.shipmentItemsKey)) {
        throw new Error(`${this.shipmentItemsKey} is not loaded`);
      }
      return new ShipmentCollection(this.get(this.shipmentItemsKey) || []);
    },
  });
});
