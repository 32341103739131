define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/common/components/string',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment, String,
) => AbstractPrintable.extend({

  headerSpacing: 40,
  rowSpacing: 40,

  build(data) {
    this.infoTable(data);
    this.builder.linefeed();

    this.builder.line();
    this.turnoverTable(data.turnover_data);
    this.builder.line();
    this.ordersTable(data.turnover_data);
    this.builder.line();
    this.vatTable(data.turnover_data);
    this.builder.line();
    this.discountTable(data.turnover_data);
    this.builder.line();
    this.paymentMethodsTable(data.turnover_data);
    this.builder.line();
    this.otherTable(data.turnover_data);
    this.builder.line();
    this.balanceTable(data.turnover_data);

    this.builder
      .line()
      .cut();

    return this.serialize();
  },

  infoTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: `${Locale.translate('start_date')}:`,
              value: new Moment(data.start_date).format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              name: `${Locale.translate('end_date')}:`,
              value: new Moment(data.end_date).format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              name: `${Locale.translate('currency')}:`,
              value: data.currency_iso3,
            },
          },
        ],
      });
  },

  turnoverTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 30,
            alignHTRows: 'left',
          },
          {
            key: 'without_tax',
            width: 30,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'with_tax',
            width: 40,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('turnover'),
              without_tax: Locale.translate('excl_vat_turnover'),
              with_tax: Locale.translate('incl_vat_turnover'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              type: Locale.translate('sale'),
              without_tax: String.roundNumber(data.invoiced_without_tax),
              with_tax: String.roundNumber(data.invoiced_with_tax),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              type: Locale.translate('return'),
              without_tax: String.roundNumber(data.refunded_without_tax),
              with_tax: String.roundNumber(data.refunded_with_tax),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              type: '',
              without_tax: String.roundNumber(data.turnover_without_tax),
              with_tax: String.roundNumber(data.turnover_with_tax),
            },
            bold: true,
          },
        ],
      });
  },

  ordersTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 30,
            alignHTRows: 'left',
          },
          {
            key: 'without_tax',
            width: 30,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'with_tax',
            width: 40,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('orders'),
              without_tax: Locale.translate('excl_vat_turnover'),
              with_tax: Locale.translate('incl_vat_turnover'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              type: Locale.translate('on_order'),
              without_tax: String.roundNumber(data.not_invoiced_without_tax),
              with_tax: String.roundNumber(data.not_invoiced_with_tax),
            },
          },
        ],
      });
  },

  vatTable(data) {
    const vatRows = [];

    for (const [vat, amount] of Object.entries(data.taxes)) {
      vatRows.push({
        data: {
          type: `${Locale.translate('vat')} ${vat}%`,
          total: String.roundNumber(amount),
        },
        lineSpacing: this.rowSpacing,
      });
    }

    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 50,
            alignHTRows: 'left',
          },
          {
            key: 'total',
            width: 50,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('vat'),
              total: Locale.translate('total'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          ...vatRows,
          {
            data: {
              type: '',
              total: String.roundNumber(data.tax_total),
            },
            bold: true,
          },
        ],
      });
  },

  discountTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 30,
            alignHTRows: 'left',
          },
          {
            key: 'with_tax',
            width: 30,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'percentage',
            width: 40,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('discount'),
              with_tax: Locale.translate('incl_vat_turnover'),
              percentage: Locale.translate('percentage'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              type: Locale.translate('sale'),
              with_tax: String.roundNumber(data.discount_with_tax),
              percentage: `${data.discount_percentage}%`,
            },
          },
        ],
      });
  },

  paymentMethodsTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'payment_method',
            width: 40,
            alignHTRows: 'left',
          },
          {
            key: 'in',
            width: 20,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'out',
            width: 20,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'total',
            width: 20,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              payment_method: Locale.translate('payment_method'),
              in: Locale.translate('in'),
              out: Locale.translate('out'),
              total: Locale.translate('total'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('cash'),
              in: String.roundNumber(data.payments.cash.in),
              out: String.roundNumber(data.payments.cash.out),
              total: String.roundNumber(data.payments.cash.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('pincreditcard'),
              in: String.roundNumber(data.payments.terminal.in),
              out: String.roundNumber(data.payments.terminal.out),
              total: String.roundNumber(data.payments.terminal.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('own_gift_card'),
              in: String.roundNumber(data.payments.gift_cards.in),
              out: String.roundNumber(data.payments.gift_cards.out),
              total: String.roundNumber(data.payments.gift_cards.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('external_gift_cards'),
              in: String.roundNumber(data.payments.external_gift_cards.in),
              out: String.roundNumber(data.payments.external_gift_cards.out),
              total: String.roundNumber(data.payments.external_gift_cards.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('internet_payments'),
              in: String.roundNumber(data.payments.web.in),
              out: String.roundNumber(data.payments.web.out),
              total: String.roundNumber(data.payments.web.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: Locale.translate('other'),
              in: String.roundNumber(data.payments.other.in),
              out: String.roundNumber(data.payments.other.out),
              total: String.roundNumber(data.payments.other.total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              payment_method: '',
              in: String.roundNumber(data.payment_in),
              out: String.roundNumber(data.payment_out),
              total: String.roundNumber(data.payment_total),
            },
            bold: true,
          },
        ],
      });
  },

  otherTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 60,
            alignHTRows: 'left',
          },
          {
            key: 'in',
            width: 20,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'out',
            width: 20,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('other'),
              in: Locale.translate('in'),
              out: Locale.translate('out'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              type: Locale.translate('cash_drawer_manual'),
              in: String.roundNumber(data.manual_mutation_in),
              out: String.roundNumber(data.manual_mutation_out),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              type: Locale.translate('skimmed'),
              in: '-',
              out: String.roundNumber(data.skimmed_total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              type: Locale.translate('emballage_turnover'),
              in: '-',
              out: String.roundNumber(data.emballage_out),
            },
          },
        ],
      });
  },

  balanceTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'type',
            width: 50,
            alignHTRows: 'left',
          },
          {
            key: 'total',
            width: 50,
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: [
          {
            data: {
              type: Locale.translate('balance'),
              total: Locale.translate('total'),
            },
            bold: true,
            lineSpacing: this.headerSpacing,
          },
          {
            data: {
              type: Locale.translate('cash_difference'),
              total: String.roundNumber(data.cash_difference_total),
            },
            lineSpacing: this.rowSpacing,
          },
          {
            data: {
              type: Locale.translate('other_difference'),
              total: String.roundNumber(data.other_difference_total),
            },
          },
        ],
      });
  },

}));
