define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment,
) => AbstractPrintable.extend({

  build(data) {
    this.companyLogo();

    this.builder
      .linefeed()
      .text(Locale.translate('invoice_payment'),
        {
          bold: true, newLine: true, size: 2, alignHT: 'center',
        })
      .linefeed();

    // Header text
    if (data.header_text) {
      this.headerText(data.header_text);
    }

    // Customer name
    if (data.customer_name) {
      this.builder
        .text(Locale.translate('customer'), { bold: true, newLine: true })
        .text(data.customer_name, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    this.productsTable(data);
    this.priceTable(data);
    this.paymentMethodsTable(data);
    this.extraInfoTable(data);
    this.receiptSignature(data.transaction_signature);

    // Pin receipt
    if (data.pin_receipt) {
      this.pinReceipt(data.pin_receipt);
    }

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  productsTable(data) {
    const productRows = [];

    data.invoice_rows.forEach((item) => {
      productRows.push(
        {
          data: {
            quantity: `${item.quantity}x `,
            product: item.name,
            ppu: item.ppu_wt,
            price: item.price_wt,
          },
        },
      );

      if (item.is_discounted && item.before_discount_ppu_wt) {
        productRows.push(
          {
            data: {
              quantity: '> ',
              product: Locale.translate('from_{0}_for_{1}', [
                item.before_discount_ppu_wt.toFixed(2),
                item.ppu_wt.toFixed(2),
              ]),
              ppu: '',
              price: '',
            },
            size: 0.5,
          },
        );
      }
    });

    if (productRows.length > 0) {
      this.builder.table({
        columns: [
          {
            key: 'quantity',
            width: 15,
            alignHTRows: 'right',
          },
          {
            key: 'product',
            width: 53,
            header: Locale.translate('product'),
          },
          {
            key: 'ppu',
            width: 17,
            header: Locale.translate('ppu_receipt'),
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'price',
            width: 17,
            header: Locale.translate('price'),
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: productRows,
      })
        .line();
    }
  },

  priceTable(data) {
    this.builder.table({
      columns: [
        {
          key: 'name',
          width: 60,
        },
        {
          key: 'value',
          width: 40,
          alignHTRows: 'right',
        },
      ],
      rows: [
        {
          data: {
            name: Locale.translate('total_in_{0}', data.currency_iso3),
            value: data.value_wt.toFixed(2),
          },

          bold: true,
        },
        data.has_discount && {
          data: {
            name: Locale.translate('total_discount_in_{0}', data.currency_iso3),
            value: data.total_discount_wt.toFixed(2),
          },
        },
        ...data.vat_totals.map((vatTotal) => ({
          data: {
            name: Locale.translate('vat_{0}', vatTotal.label),
            value: vatTotal.total,
          },
        })),
      ],
    });
  },

  paymentMethodsTable(data) {
    const paymentMethodRows = data.payment_methods ? data.payment_methods.map((paymentMethod) => ({
      data: {
        name: `${paymentMethod.title}:`,
        value: paymentMethod.ppu_wt,
      },
    })) : [];

    if (data.change) {
      paymentMethodRows.push({
        data: {
          name: `${Locale.translate('spare_change')}:`,
          value: data.change,
        },
      });
    }

    if (paymentMethodRows.length > 0) {
      this.builder
        .line()
        .table({
          columns: [
            {
              key: 'name',
              width: 55,
              alignHTRows: 'right',
            },
            {
              key: 'value',
              width: 45,
              alignHTRows: 'right',
            },
          ],
          rows: paymentMethodRows,
        });
    }
  },

  extraInfoTable(data) {
    const extraInfoRows = [
      {
        data: {
          name: Locale.translate('sales_person'),
          value: data.cashier_name,
        },
      },
      {
        data: {
          name: Locale.translate('date'),
          value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
        },
      },
      {
        data: {
          name: Locale.translate('number_receipt'),
          value: `#${data.number}`,
        },
      },
    ];

    this.builder
      .line()
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: extraInfoRows,
      });
  },

}));
