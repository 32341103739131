define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/currency',
], (
  $, _, Backbone,
) => Backbone.DeepModel.extend({

  idAttribute: 'id',

  defaults: {
    ppu_wt: '0.00',
    setManually: false,
  },

  modelEvents: {
    'change:price_wt': 'warnPrice',
  },

  setPpuWt(ppu_wt) {
    this.set({ ppu_wt });
  },

  warnPrice() {
    console.warn('price_wt should not be set');
  },
}));
