define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment,
) => AbstractPrintable.extend({

  build(data) {
    if (data.options && data.options.number) {
      this.builder
        .text(data.options.number.toString(), {
          size: 4, bold: true, alignHT: 'center', newLine: true,
        })
        .linefeed();
    }

    // Header text
    if (data.header_text) {
      this.headerText(data.header_text);
    }

    this.builder.text('{~reprint~}');

    // Customer name
    if (data.customer_name) {
      this.builder
        .text(Locale.translate('customer_receipt'), { bold: true, newLine: true })
        .text(data.customer_name, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    // Description
    if (data.description) {
      this.builder
        .text(Locale.translate('description'), { bold: true, newLine: true })
        .text(data.description, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    this.productsTable(data);
    this.extraInfoTable(data);

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  productsTable(data) {
    const productRows = [];

    data.order_items.forEach((orderItem) => {
      productRows.push(
        {
          data: {
            quantity: `${orderItem.quantity}x `,
            product: orderItem.name,
          },
        },
      );

      if (orderItem.description) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: orderItem.description,
            },
            size: 0.5,
          },
        );
      }

      if (orderItem.is_discounted && orderItem.before_discount_ppu_wt) {
        productRows.push(
          {
            data: {
              quantity: '> ',
              product: Locale.translate('from_{0}_for_{1}', [
                orderItem.before_discount_ppu_wt.toFixed(2),
                orderItem.ppu_wt.toFixed(2),
              ]),
            },
            size: 0.5,
          },
        );
      }

      if (orderItem.subitems) {
        for (const subItem of orderItem.subitems) {
          productRows.push(
            {
              data: {
                quantity: '',
                product: `+ ${subItem.name}`,
              },
            },
          );
        }
      }
    });

    if (productRows.length > 0) {
      this.builder
        .table({
          columns: [
            {
              key: 'quantity',
              width: 15,
              alignHTRows: 'right',
            },
            {
              key: 'product',
              width: 85,
              header: Locale.translate('product'),
            },
          ],
          rows: productRows,
        })
        .line();
    }
  },

  extraInfoTable(data) {
    const extraInfoRows = [
      {
        data: {
          name: Locale.translate('date'),
          value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
        },
      },
      {
        data: {
          name: Locale.translate('number'),
          value: `#${data.number}`,
        },
      },
    ];

    this.builder
      .table({
        columns: [
          {
            key: 'name',
            width: 30,
          },
          {
            key: 'value',
            width: 70,
          },
        ],
        rows: extraInfoRows,
      });
  },

}));
