define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
], (
  $, _, Backbone, AppLocalStorage,
) => {
  const Model = Backbone.Model.extend({

    defaults: {
      lastId: 0,
      // by default take current time, than i won't double sync on init
      lastUpdateDate: new Date(),
    },

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/productCacheConfig'),

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    setLastId(lastId) {
      const id = this.getLastId();
      if (id < lastId) {
        this.set('lastId', lastId);
        this.save();
      }
    },
    getLastId() {
      return this.get('lastId');
    },

    getUpdateDateIds() {
      return this.get('lastUpdateIds') || [];
    },
    setUpdateDateIds(time, ids) {
      const lastDate = new Date(this.get('lastUpdateDate'));
      if (lastDate.getTime() === time.getTime()) {
        // same date merge the ids
        ids = ids.concat(this.getUpdateDateIds());
      } else {
        // date changed, we just use the ids we have
      }
      this.unset('lastUpdateIds');
      this.set({
        lastUpdateDate: time,
        lastUpdateIds: ids,
      });
      this.save();
    },

    getLastUpdateDate() {
      const date = this.get('lastUpdateDate');
      if (date) {
        return new Date(date);
      }
      return new Date(0);
    },

    resetToDefaults() {
      this.set(this.defaults);
      this.save();
    },
  });

  return new Model();
});
