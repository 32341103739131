define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractEscposPrinter',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/environment',
  'modules/common/components/locale',

  './webPrinterDevice',

  'dom-to-image',
  'esc-pos-encoder',
  '@storekeeper/escpos',
  '@storekeeper/escpos-browser'
], (
  $, _, Backbone, AbstractEscposPrinter,
  DeepModelCollection,
  CashRegisterApi, Environment, Locale, WebPrinterDevice,
  DomToImage, EscPosEncoder, EscPos, EscPosBrowser
) => AbstractEscposPrinter.extend({

  // Setup which vendor id's to filter on
  VENDOR_ID_REGO: 1155,
  VENDOR_ID_EPSON: 1208,

  module: null,

  getWantedType() {
    return this.PRINTER_TYPE_WEB;
  },

  selectDevice() {
    const filters = [{ vendorId: this.VENDOR_ID_REGO }, { vendorId: this.VENDOR_ID_EPSON }];
    return navigator.usb.requestDevice({ filters });
  },

  claimDevice(device) {
    return new Promise((async (resolve, reject) => {
      try {
        await device.open();
        await device.selectConfiguration(1);
        await device.claimInterface(device.configuration.interfaces[0].interfaceNumber);
        const { endpointNumber } = device.configuration.interfaces[0].alternate.endpoints.find((ep) => ep.direction === 'in');

        console.log('Sending two feed commands to assure connection');
        await device.transferOut(endpointNumber, new Uint8Array([0x1b, 0x40, 0x0a, 0x0a]));

        WebPrinterDevice.setDevice(device);
        WebPrinterDevice.setEndpointNumber(endpointNumber);

        resolve(endpointNumber);
      } catch (err) {
        reject(err);
      }
    }));
  },

  printImage(blob, def, startTime) {
    const reader = new FileReader();
    reader.onerror = (event) => {
      console.error('[ESCPOS] base64data failed', event);
      reader.abort();
      def.reject();
    };

    reader.onloadend = () => {
      this.logTime('image as base64', startTime);
      // read image
      const base64data = reader.result;

      const $img = $('<img/>');
      $('body').append($img);// needs to appended to render

      if (Environment.isDevelopment()) {
        $img.css('position', 'fixed');
        $img.css('bottom', '10px');
        $img.css('border', 'red solid 1px');
        $img.css('right', '10px');
      }

      $img.attr('src', base64data);
      $($img).on('load', async () => {
        this.logTime('image as base64 -> loaded', startTime);
        try {
          // make sure it's divisable by 8 (required by EscPosEncoder lib)
          let width = $img.width();
          let height = $img.height();
          width += (8 - width % 8) % 8;
          height += (8 - height % 8) % 8;

          const encoder = new EscPosEncoder();
          const result = encoder
            .initialize()
            .image($img.get(0), width, height)
            .encode();

          this.logTime('image as EscPos', startTime);
          console.debug(`[ESCPos] Not zero: ${result.filter((x) => x != 0).length}`);
          await WebPrinterDevice.printEscPos(result);
          this.logTime('EscPos send to printer', startTime);

          def.resolve();
        } catch (e) {
          console.error('[ESCPOS] failed to print escpos', e);
          def.reject();
        }
      });

      def.always(
        () => $img.remove(),
      );
    };

    reader.readAsDataURL(blob);
  },

  printHtmlFn(htmlText) {
    const def = new $.Deferred();

    this.convertHtmlToEscposJson(htmlText)
      .then((jsonData) => {
        this.printJsonData(jsonData).then(def.resolve, def.reject);
      }, def.reject);

    return def;
  },

  printJsonData(jsonData, printerId) {
    const def = new $.Deferred();
    printerId = printerId || this.get('id');

    this.escposJsonToIntArray(jsonData).then(intArray => {
      WebPrinterDevice.printEscPos(intArray)
        .then(def.resolve).catch(def.reject)

    }, def.reject);

    return def;
  },

  escposJsonToIntArray(jsonData) {
    const def = new $.Deferred();

    const builder = EscPos.EscPosBuilder.from(jsonData);
    builder.build({
      imageProvider: new EscPosBrowser.BrowserImageProvider()
    }).then(def.resolve).catch(def.reject)

    return def;
  }

}));
