define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/profile/models/profile',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment, Profile,
) => AbstractPrintable.extend({

  build(data) {
    this.companyLogo();

    if (data.header_text) {
      this.headerText(data.header_text);
    }

    this.builder
      .text(Locale.translate('collect_confirmation'), {
        size: 2, newLine: true, alignHT: 'center', bold: true,
      })
      .linefeed();

    this.productsTable(data.shipment.shipment_items);
    this.extraInfoTable(data);

    this.builder.linefeed();

    this.signature();

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  productsTable(items) {
    const productRows = [];

    items.forEach((item) => {
      productRows.push(
        {
          data: {
            quantity: `${item.quantity}x`,
            product: item.name,
          },
        },
      );

      if (item.sku) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: item.sku,
            },
            size: 0.5,
          },
        );
      }

      if (item.serial_nos && item.serial_nos.length) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: `${Locale.translate('serials')}: ${item.serial_nos.join(', ')}`,
            },
            size: 0.5,
          },
        );
      }
    });

    if (productRows.length > 0) {
      this.builder.table({
        columns: [
          {
            key: 'quantity',
            width: 20,
            header: Locale.translate('qty_dot'),
          },
          {
            key: 'product',
            width: 80,
            alignHTRows: 'right',
            alignHTHeader: 'right',
            header: Locale.translate('products'),
          },
        ],
        rows: productRows,
      }).line();
    }
  },

  extraInfoTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: Locale.translate('purchase_date'),
              value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              name: Locale.translate('pickup_date'),
              value: new Moment().format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              name: Locale.translate('order_number'),
              value: `#${data.number}`,
            },
          },
          {
            data: {
              name: Locale.translate('package_number'),
              value: `#${data.number}-${data.shipment.id}`,
            },
          },
          {
            data: {
              name: Locale.translate('sales_person'),
              value: Profile.getFullProfileName(),
            },
          },
        ],
      });
  },
}));
