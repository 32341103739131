define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractPrinter',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/environment',
  'modules/common/components/locale',
  'modules/shop.common/components/mode',

  'modules/shop.cash-register-retail/models/settings/mediaType',
  'modules/shop.cash-register-retail/models/electronWebViewHandler',
], (
  App, $, _, Backbone, AbstractPrinter,
  DeepModelCollection,
  CashRegisterApi, Environment, Locale, Mode,
  MediaTypeModel, ElectronWebViewHandler,
) => AbstractPrinter.extend({

  PRINTER_REGO_CUBE: '0483:5720', // REGO PRINTER
  PRINTER_EPSON_TMT20II: '04b8:0e15', // Epson TM-T20II
  PRINTER_ZTC_GC420d: '0a5f:00d1', // ZTC GC420d (EPL)
  PRINTER_XPRINTER_ZPL: '2d37:41a6', // Storekeeper 108mm (ZPL) model 470B 2020
  PRINTER_XPRINTER_420B_ZPL: '2d84:71a9', // Storekeeper 108mm (ZPL) model 420B 2021

  // dev printers
  PRINTER_DEV_LP: 'StoreKeeper:lp',
  PRINTER_DEV_ZPL: 'StoreKeeper:zpl',

  initialize(options) {
    AbstractPrinter.prototype.initialize.call(this, options);
    // Dictionary(-ish) of supported printers and their types
    this.printer_type_dict = [];
    this.printer_type_dict[this.PRINTER_REGO_CUBE] = this.PRINTER_TYPE_ESCPOS;
    this.printer_type_dict[this.PRINTER_EPSON_TMT20II] = this.PRINTER_TYPE_ESCPOS;
    this.printer_type_dict[this.PRINTER_ZTC_GC420d] = this.PRINTER_TYPE_ZPL;
    this.printer_type_dict[this.PRINTER_XPRINTER_ZPL] = this.PRINTER_TYPE_ZPL;
    this.printer_type_dict[this.PRINTER_XPRINTER_420B_ZPL] = this.PRINTER_TYPE_ZPL;
    this.printer_type_dict[this.PRINTER_DEV_LP] = this.PRINTER_TYPE_ESCPOS;
    this.printer_type_dict[this.PRINTER_DEV_ZPL] = this.PRINTER_TYPE_ZPL;
  },

  getPrinterInformation(vendor, prod_id) {
    // Retrieve the printer type, or type_none when not found
    const k = `${vendor}:${prod_id}`;
    if (k in this.printer_type_dict) {
      return this.printer_type_dict[k];
    }
    return this.PRINTER_TYPE_NONE;
  },

  getSpecialPrinterName(vendorId, productId, serial) {
    const k = `${vendorId}:${productId}`;
    if (k === this.PRINTER_REGO_CUBE) {
      return `StoreKeeper Cube (SN: ${serial})`;
    }
    if (k === this.PRINTER_XPRINTER_ZPL || k === this.PRINTER_XPRINTER_420B_ZPL) {
      return `StoreKeeper XPrinter (SN: ${serial})`;
    }

    return null;
  },

  getWantedType() {
    return this.PRINTER_TYPE_LP;
  },

  fetchPrintersElectron() {
    const def = new $.Deferred();

    ElectronWebViewHandler.getUSBPrinters().then((printers) => {
      printers = printers.map((printer) => {
        const specialName = this.getSpecialPrinterName(
          printer.vendorId,
          printer.productId,
          printer.serialNumber,
        );
        if (specialName != null) {
          printer.name = specialName;
        }

        // Type of electron usb printers can only be PRINTER_TYPE_ESCPOS
        printer.type = this.PRINTER_TYPE_ESCPOS;
        return printer;
      });

      def.resolve({
        printers: new DeepModelCollection(printers),
      });
    }, def.reject);

    return def;
  },

  fetchPrintersNodeApi() {
    const def = new $.Deferred();

    CashRegisterApi.call('/lp-printers', 'GET')
      .then((response) => {
        response.forEach((printer) => {
          printer.type = this.getPrinterInformation(printer.data.Vendor, printer.data.ProdID);

          const specialName = this.getSpecialPrinterName(
            printer.data.Vendor,
            printer.data.ProdID,
            printer.data.SerialNumber,
          );
          if (specialName != null) {
            printer.name = specialName;
          }
        });

        def.resolve({
          printers: new DeepModelCollection(response),
        });
      }, (resp) => {
        def.reject(resp);
      });
    return def;
  },

  fetchPrintersFn() {
    if (Mode.isInElectronMode()) {
      return this.fetchPrintersElectron();
    }

    return this.fetchPrintersNodeApi();
  },

  printHtmlFn(htmlText, options) {
    const self = this;

    const def = new $.Deferred();
    const printerId = this.get('id');

    if (Environment.isDevelopment()) this.showReceiptPreview(htmlText);

    const data = new FormData();
    data.append('html', htmlText);

    let api_path;
    switch (self.get('type')) {
      case self.PRINTER_TYPE_ESCPOS:
        api_path = '/escpos-printers/';
        break;
      case self.PRINTER_TYPE_ZPL:
        api_path = '/zpl-printers/';
        data.append('media', MediaTypeModel.get('id'));
        break;
      default:
        // Fallback to ZPL because since we still use abstractEscposPrinter for receipts
        api_path = '/zpl-printers/';
        data.append('media', MediaTypeModel.get('id'));
        break;
    }

    CashRegisterApi.call(`${api_path + printerId}/print/${options.type}`, 'post', data, { timeout: 15000 }).then(() => {
      def.resolve();
    }, (response) => {
      def.reject({
        error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
        error_title: Locale.translate('failed_to_print_on_printer_with_id_{0}', [printerId]),
        error_message: response.error,
      });
    });
    return def;
  },
}));
