define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',

  'modules/shop.cash-register-retail/collections/upx/PaymentProvider.js',
  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod.js',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/feature',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/shopPos',
], (
  $, _, Backbone, AppLocalStorage,
  ProviderCollection, ProviderMethodCollection,
  Locale, Feature,
  PointOfSaleSetting, ShopPos,
) => {
  const Model = Backbone.DeepModel.extend({

    PIN_METHOD: 'pin',
    PIN_EXTRA_METHOD: 'pin_cash_withdrawal',
    GIFTCARD_METHOD: 'gift_card',
    EXTERNAL_GIFTCARD_METHOD: 'external_gift_card',
    PAYLATER_METHOD: 'pay_later',
    CASH_METHOD: 'cash',
    VVV_METHOD: 'vvv',
    INVOICE_METHOD: 'invoice',
    OTHER_METHOD: 'other',
    QR_CODE_METHOD: 'qr_code',

    LOCKED_METHOD: 'locked',

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/paymentMethods'),

    initialize() {
      this.fetch();
      this.loaded = false;
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set({ methods: null });
    },

    setMethods(methods) {
      this.unset('methods');
      this.set('methods', methods);
      this.save();
    },

    enabledByMethod(method) {
      const methods = ShopPos.get('payment_categories');
      return _.contains(methods, method);
    },

    load(force) {
      const self = this;
      const def = new $.Deferred();

      if (!this.loaded || force) {
        $.when(
          Feature.load(),
          ProviderCollection.load(force),
          ProviderMethodCollection.load(force),
        ).then(() => {
          self.loaded = true;
          def.resolve();
        }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    areAvailabilityLoaded() {
      if (!this.loaded) {
        throw Error('availabilities not loaded');
      }
    },

    getTerminalDevicesCollection() {
      this.areAvailabilityLoaded();
      return ProviderMethodCollection.getFilteredCollection((model) => model.get('provider_method_type.alias') === 'TerminalDevice');
    },

    getEnabledPaymentMethodCollection() {
      const collection = new Backbone.Collection();
      const addToCollection = (method) => {
        collection.add({
          id: method,
          requires_customer: this.requiresCustomerByMethod(method),
          rest_value: this.allowsRestPaidByMethod(method),
          convert_to_change: this.convertToChangeByMethod(method),
        });
      };

      if (this.pinEnabled()) {
        addToCollection(this.PIN_METHOD);
      }

      if (this.pinExtraEnabled()) {
        addToCollection(this.PIN_EXTRA_METHOD);
      }

      if (this.giftEnabled()) {
        addToCollection(this.GIFTCARD_METHOD);
      }

      if (this.laterEnabled() && Feature.getFeatureActiveByAppName(
        Feature.APP_CORE_SHOP_MODULE_BACKOFFICE_ORDER_PROCESSING,
      )) {
        addToCollection(this.PAYLATER_METHOD);
      }

      if (this.cashEnabled()) {
        addToCollection(this.CASH_METHOD);
      }

      if (this.qrEnabled()) {
        addToCollection(this.QR_CODE_METHOD);
      }

      if (this.invoiceEnabled() && Feature.getFeatureActiveByAppName(
        Feature.APP_NAME_CORE_BILLING_MODULE_INVOICING,
      )) {
        addToCollection(this.INVOICE_METHOD);
      }

      if (this.otherEnabled()) {
        addToCollection(this.OTHER_METHOD);
      }

      return collection;
    },

    getAllowedPaymentMethodCollection() {
      const collection = new Backbone.Collection();
      const addToCollection = (method) => {
        collection.add({
          id: method,
          logo: this.getIconByMethod(method),
          enabled: this.enabledByMethod(method),
          name: this.getNameByMethod(method),
        });
      };

      if (this.pinAllowed()) {
        addToCollection(this.PIN_METHOD);
        addToCollection(this.PIN_EXTRA_METHOD);
      }

      if (this.giftAllowed()) {
        addToCollection(this.GIFTCARD_METHOD);
      }

      if (this.laterAllowed()) {
        addToCollection(this.PAYLATER_METHOD);
      }

      if (this.cashAllowed()) {
        addToCollection(this.CASH_METHOD);
      }

      if (this.qrAllowed()) {
        addToCollection(this.QR_CODE_METHOD);
      }

      if (this.invoiceAllowed()) {
        addToCollection(this.INVOICE_METHOD);
      }

      if (this.otherAllowed()) {
        addToCollection(this.OTHER_METHOD);
      }

      return collection;
    },

    otherAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'Other');
      return filtered.length > 0;
    },

    otherEnabled() {
      return this.enabledByMethod(this.OTHER_METHOD) && this.otherAllowed();
    },

    pinAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'TerminalDevice');
      return filtered.length > 0;
    },

    pinEnabled() {
      return this.enabledByMethod(this.PIN_METHOD) && this.pinAllowed();
    },

    pinExtraEnabled() {
      return this.enabledByMethod(this.PIN_EXTRA_METHOD) && this.pinAllowed();
    },

    giftAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'GiftCard');
      return filtered.length > 0;
    },

    giftEnabled() {
      return this.enabledByMethod(this.GIFTCARD_METHOD) && this.giftAllowed();
    },

    laterAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'OnOrder');
      return filtered.length > 0;
    },

    laterEnabled() {
      return this.enabledByMethod(this.PAYLATER_METHOD) && this.laterAllowed();
    },

    cashAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'Cash');
      return filtered.length > 0;
    },

    cashEnabled() {
      return this.enabledByMethod(this.CASH_METHOD) && this.cashAllowed();
    },

    qrAllowed() {
      this.areAvailabilityLoaded();
      const model = ProviderCollection.getActiveUpxPayOrPayNlProvider();

      if (!model) {
        console.warn('qrAllowed disabled: There are no UpxPay/PayNl providers enabled');
      }

      // get boolean value
      return !!model;
    },

    qrEnabled() {
      return this.enabledByMethod(this.QR_CODE_METHOD) && this.qrAllowed();
    },

    invoiceAllowed() {
      this.areAvailabilityLoaded();
      const filtered = ProviderMethodCollection.filter((model) => model.get('provider_method_type.alias') === 'OnInvoice');
      return filtered.length > 0;
    },

    invoiceEnabled() {
      return this.enabledByMethod(this.INVOICE_METHOD);
    },

    getMethod(method) {
      if (method.indexOf('::') !== -1) {
        return method.split('::')[0];
      }
      return method;
    },

    requiresCustomerByMethod(method) {
      method = this.getMethod(method);
      switch (method) {
        case this.PAYLATER_METHOD:
        case this.INVOICE_METHOD:
          return true;
      }
      return false;
    },

    allowsRestPaidByMethod(method) {
      method = this.getMethod(method);
      switch (method) {
        case this.OTHER_METHOD:
        case this.PIN_METHOD:
        case this.GIFTCARD_METHOD:
        case this.EXTERNAL_GIFTCARD_METHOD:
        case this.PAYLATER_METHOD:
        case this.CASH_METHOD:
        case this.QR_CODE_METHOD:
        case this.INVOICE_METHOD:
          return true;
      }
      return false;
    },

    convertToChangeByMethod(method) {
      method = this.getMethod(method);
      switch (method) {
        case this.PIN_EXTRA_METHOD:
          return true;
      }
      return false;
    },

    getNameByMethod(method) {
      method = this.getMethod(method);
      switch (method) {
        case this.OTHER_METHOD:
          return Locale.translate('other');
        case this.PIN_METHOD:
          return Locale.translate('pin');
        case this.PIN_EXTRA_METHOD:
          return Locale.translate('cash_withdrawal');
        case this.GIFTCARD_METHOD:
          return Locale.translate('gift_card');
        case this.EXTERNAL_GIFTCARD_METHOD:
          return Locale.translate('external_gift_card');
        case this.PAYLATER_METHOD:
          return Locale.translate('pay_later');
        case this.CASH_METHOD:
          return Locale.translate('cash');
        case this.QR_CODE_METHOD:
          return Locale.translate('qr_code');
        case this.VVV_METHOD:
          return Locale.translate('vvv_card');
        case this.INVOICE_METHOD:
          return Locale.translate('invoice');
        default:
          return Locale.translate(method);
      }
    },

    getIconByMethod(method) {
      method = this.getMethod(method);
      switch (method) {
        case this.OTHER_METHOD:
          return 'fal fa-ellipsis-h fa-4x';
        case this.PIN_METHOD:
          return 'fal fa-credit-card fa-4x';
        case this.PIN_EXTRA_METHOD:
          return 'fal fa-hand-holding-usd fa-4x';
        case this.GIFTCARD_METHOD:
        case this.EXTERNAL_GIFTCARD_METHOD:
          return 'fal fa-gift-card fa-4x';
        case this.PAYLATER_METHOD:
          return 'fal fa-clock fa-4x';
        case this.CASH_METHOD:
          return 'fal fa-money-bill-alt fa-4x';
        case this.QR_CODE_METHOD:
          return 'fal fa-qrcode fa-4x';
        case this.VVV_METHOD:
          return 'fal fa-money-check-alt fa-4x';
        case this.INVOICE_METHOD:
          return 'fal fa-file-invoice-dollar fa-4x';
      }
    },

    getMethodArray() {
      return [
        this.PIN_METHOD,
        this.PIN_EXTRA_METHOD,
        this.GIFTCARD_METHOD,
        this.EXTERNAL_GIFTCARD_METHOD,
        this.PAYLATER_METHOD,
        this.CASH_METHOD,
        this.QR_CODE_METHOD,
        this.VVV_METHOD,
        this.INVOICE_METHOD,
        this.OTHER_METHOD,
      ];
    },

    methodExists(id) {
      const existsById = this.getMethodArray().indexOf(id) !== -1;
      const isGiftCardMethod = id.startsWith(this.GIFTCARD_METHOD);
      const isExternalGiftCardMethod = id.startsWith(this.EXTERNAL_GIFTCARD_METHOD);
      const isLocked = id.startsWith(this.LOCKED_METHOD);
      return existsById || isGiftCardMethod || isExternalGiftCardMethod || isLocked;
    },

  });

  return new Model();
});
