define([
  'jquery',
  'upx.modules/ShopModule/models/Configuration',
  'modules/upx/components/upx',
  'modules/profile/models/profile',
  'modules/common/components/locale',

  'upx.modules/RelationsModule/models/Relation',
  'modules/shop.cash-register-retail/models/customerScreenData',
  'modules/shop.common/collections/upx/NaturalSearchShopFlatProduct',

], (
  $, ShopModule, Upx, ProfileModel, Locale,
  RelationModel, CustomerScreenDataModel, NaturalSearchShopFlatProductCollection,
) => {
  const model = ShopModule.extend({

    offline: true,

    loaded: false,

    emballage_product: false,

    load(force) {
      const def = $.Deferred();

      if (force) this.unload();

      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ShopModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'is_default__=',
              val: 'true',
            }],
          })).then((response) => {
          self.set(response.data[0]);
          const relationModel = new RelationModel({
            id: self.get('relation_data_id'),
          });
          relationModel.fetch().then(() => {
            self.relation = relationModel;
            self.loaded = true;
            def.resolve();
            if (relationModel.has('image_url')) {
              CustomerScreenDataModel.setCompanyLogo(relationModel.get('image_url'));
            }
          });
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    /**
         * Returns the main currencyIso3
         * @returns {*}
         */
    getCurrencyIso3() {
      return this.get('currency_iso3');
    },

    getDefaultTaxRateId() {
      return this.get('tax_rate_default_id');
    },

    getPaymentTaxRateId() {
      return this.get('tax_rate_default_payment_id');
    },

    getShipmentTaxRateId() {
      return this.get('tax_rate_default_shipment_id');
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
      this.relation = null;
      this.loaded = false;
    },

    getRelation() {
      return this.relation;
    },

    getCompanyLogo() {
      const relation = this.getRelation();
      if (relation && relation.has('business_data.logo_url')) {
        return Upx.getResourceUrl(relation.get('business_data.logo_url'));
      }
      return '';
    },

    getCompanyName() {
      const relation = this.getRelation();
      if (relation && relation.has('name')) {
        return relation.get('name');
      }
      return '';
    },

    getBlackAndWhiteCompanyLogo() {
      const relation = this.getRelation();
      if (relation && relation.has('business_data.logo_black_white_url')) {
        return Upx.getResourceUrl(relation.get('business_data.logo_black_white_url'));
      }
      return '';
    },

    getMemberCardProductId() {
      return this.get('member_card_product_id');
    },

    getEmballageProductId() {
      return this.get('emballage_product_id');
    },
    getFallbackShippingMethodId() {
      return this.get('synchronisation_fallback_shipping_method_id');
    },
    getFallbackBackorderStatus() {
      return this.get('backorder_enabled');
    },

    getEmballageShopFlatProductModel() {
      const ensureDef = new $.Deferred();
      const def = $.Deferred();

      if (!this.has('emballage_product_id')) {
        this.ensureProducts().then(
          (data) => {
            this.unload();
            this.set(data);
            ensureDef.resolve();
          },
          ensureDef.reject,
        );
      } else {
        ensureDef.resolve();
      }

      ensureDef.then(() => {
        if (!this.emballage_product) {
          const collection = new NaturalSearchShopFlatProductCollection();
          const params = {
            params: {
              query: 0,
              lang: '',
              start: 0,
              limit: 1,
              filters: [{
                name: 'flat_product/product/id__in_list',
                multi_val: [this.getEmballageProductId()],
              }],
            },
          };

          collection.fetch(params).then(() => {
            this.emballage_product = collection.findWhere({ product_id: this.getEmballageProductId() });
            if (this.emballage_product) {
              def.resolve(this.emballage_product);
            } else {
              const response = Locale.translate('could_not_load_emballage_product');
              console.error(response);
              def.reject(response);
            }
          }, (response) => {
            console.error(response);
            def.reject(response);
          });
        } else {
          def.resolve(this.emballage_product);
        }
      }, (response) => {
        console.error(response);
        def.reject(response);
      });

      return def.promise();
    },

    getGiftCardBalanceProductId() {
      return this.get('gift_card_balance_product_id');
    },

    getUsedGoodsProductId() {
      return this.get('bought_used_goods_fallback_product_id');
    },

    getStartOfTheDay() {
      const startOfTheDay = this.get('start_of_the_day');
      let hours = 0;
      let minutes = 0;

      if (startOfTheDay) {
        [hours, minutes] = startOfTheDay.split(':');
      }

      return {
        hours: parseInt(hours),
        minutes: parseInt(minutes),
      };
    },
  });

  return new model();
});
