define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractLpPrinter',
  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
], (
  $, _, Backbone, DefaultPrinterModel, AppLocalStorage, DeviceConfig,
) => {
  const Model = DefaultPrinterModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/priceTagPrinter'),

    configPath: DeviceConfig.PATH_PriceTagPrinter,

    PRINTER_TYPE_SHELF_CARD: 'pricetag',
    PRINTER_TYPE_PRICE_STICKER: 'sticker',

    getWantedType() {
      return this.PRINTER_TYPE_ZPL;
    },

  });

  return new Model();
});
