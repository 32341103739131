define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',

  'upx.modules/ShopModule/collections/Shop',
], (
  $, _, Backbone, AppLocalStorage,
  ShopCollection,
) => {
  const Model = Backbone.DeepModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/pointOfSale'),

    initialize() {
      this.fetch();
    },

    fetch() {
      const def = new $.Deferred();

      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          this.set(this.get('0'));
          this.unset('0');
          def.resolve();
        }, def.reject);

      return def;
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set({ id: null });
    },

    setByModel(model) {
      this.clear({ silent: true });
      this.set(model.toJSON());
      this.save();
    },

    list() {
      const def = new $.Deferred();

      const shopCollection = new ShopCollection();
      const params = {
        params: {
          start: 0,
          limit: 0,
          filters: [
            {
              name: 'shop_type/alias__=',
              val: 'PointOfSale',
            },
            {
              name: 'shop_type/module_name__=',
              val: 'ShopModule',
            },
            {
              name: 'visible__=',
              val: true,
            },
          ],
        },
      };

      shopCollection.fetch(params)
        .then(() => {
          def.resolve(shopCollection);
        }, def.reject);

      return def;
    },

  });

  return new Model();
});
