define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.common/models/upx/Newsletter',
  'modules/upx/collections/users',
], (
  $, _, Backbone,
  NewsletterModel, UsersCollection,
) => {
  const Model = Backbone.Model.extend({

    defaults: {
      unread_newsletters: 0,
    },

    fetchNotifications() {
      const def = new $.Deferred();
      const self = this;

      if (UsersCollection.hasActiveUser()) {
        const newsletterModel = new NewsletterModel();
        newsletterModel.countUnread()
          .then((newsletterCount) => {
            self.set('unread_newsletters', newsletterCount);
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

  });

  return new Model();
});
