define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/shopPos',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
], (
  $, _, Backbone, ShopPosModel,
  PointOfSaleSetting,
) => {
  const Model = ShopPosModel.extend({

    getShopId() {
      return PointOfSaleSetting.get('id');
    },
    isQuickPinEnabled() {
      return this.get('quick_pin') || false;
    },
    getBarcodePatterns() {
      return {
        weighted: this.get('weighted_barcode_pattern'),
        emballage: this.get('emballage_barcode_pattern'),
      };
    },
  });

  return new Model();
});
